
import { defineComponent } from "vue";
import PhoneCard from "./PhoneCard/index.vue";
export default defineComponent({
  components: { PhoneCard },
  setup() {
    return {
      appName: "每食优享用户端App",
      msglist: [
        "1、缴纳餐费：中央厨房提供团餐缴费、退费服务，用户可以在APP上选择合适的套餐，进行缴费。如果订单日期范围内，需要停餐（某一天或几天不吃），则可以提前提交请假申请，客服做退费处理。",
        "2、菜谱管理：用户在APP上实时查看每天的菜谱、荤素营养搭配情况。",
        "3、明厨亮灶：中央厨房积极响应监管部门政策要求，用户能够通过实时直播看到中央厨房生产加工车间的情况，团餐加工厂更加透明，用户订餐更加放心。",
        "4、食材溯源：中央厨房采购的每一种食材都是由专业的食材供应商提供，用户可以查看各种食材的供应商，生产资质，生产环境等资料。",
      ],
      moduleList: [
        {
          imgurl: require("../../assets/prod-kxh/phon1-index.png"),
          title: "首页",
          msg: "首页包括banner运营位置，餐费缴纳、看我、菜谱、食材溯源等功能。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon2-jn.png"),
          title: "餐费缴纳",
          msg: "为孩子选择套餐，进行缴费，便捷支付。",
          order: false,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon3-lookme.png"),
          title: "看我",
          msg: "随时随地观看孩子在校用餐情况，了解孩子饮食健康。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon4-caipu.png"),
          title: "菜谱",
          msg: "提前了解孩子在校一月用餐菜谱，关注孩子营养数据。",
          order: false,
        },
        {
          imgurl: require("../../assets/prod-kxh/phon5-scroue.png"),
          title: "食材溯源",
          msg: "了解每一种食材的来源，让家长放心，孩子吃的安心。",
          order: true,
        },
      ],
    };
  },
});
